import React, { useState } from 'react';
import styled from '@emotion/styled';
import { IconButton } from '@material-ui/core';
import Edit from '@material-ui/icons/EditOutlined';
import { useIntl } from 'react-intl';

import Checkbox from '_shared/components/Controls/Checkbox';
import { SpecificationColumnType } from '_clients/types/types';

import UncheckDialog from './UncheckDialog';
import EditDialog from './EditDialog';

const Row = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Container = styled(Row)`
  height: 48px;
  padding: 8px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dde1e0;
`;

type ColumnItemProps = {
  column: SpecificationColumnType;
  checked: boolean;
  withData: boolean;
  onCheck: (column: SpecificationColumnType, checked: boolean) => Promise<void>;
  onChangeName: (
    column: SpecificationColumnType,
    name: string
  ) => Promise<void>;
  onDelete: (id: number) => Promise<void>;
};

const ColumnItem = ({
  column,
  checked,
  withData,
  onCheck,
  onChangeName,
  onDelete,
}: ColumnItemProps) => {
  const { formatMessage } = useIntl();

  const [loading, setLoading] = useState(false);
  const [isUncheckDialogOpen, setIsUncheckDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  const updateValue = async (value: boolean) => {
    setLoading(true);
    await onCheck(column, value);
    setLoading(false);
  };

  const handleCheck = async (value: boolean) => {
    if (withData && !value) {
      setIsUncheckDialogOpen(true);
      return;
    }

    updateValue(value);
  };

  const handleUncheckDialogSubmit = async () => {
    await updateValue(false);
    setIsUncheckDialogOpen(false);
  };

  const handleDeleteColumn = async () => {
    await onDelete(column.id);
  };

  return (
    <Container>
      <UncheckDialog
        open={isUncheckDialogOpen}
        onClose={() => setIsUncheckDialogOpen(false)}
        onSubmit={handleUncheckDialogSubmit}
      />

      <EditDialog
        open={isEditDialogOpen}
        columnName={column.name}
        onClose={() => setIsEditDialogOpen(false)}
        onSubmit={(name) => onChangeName(column, name)}
        onDelete={handleDeleteColumn}
      />

      <Checkbox
        checked={checked}
        label={column.name}
        disabled={loading}
        size="small"
        onChange={handleCheck}
      />

      <IconButton
        size="small"
        color="inherit"
        onClick={() => setIsEditDialogOpen(true)}
      >
        <Edit />
      </IconButton>
    </Container>
  );
};

export default ColumnItem;
