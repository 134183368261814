import React, {
  useMemo,
  useState,
  MouseEvent,
  FocusEvent,
  cloneElement,
  useRef,
} from 'react';
import { Tooltip as MuiTooltip, makeStyles } from '@material-ui/core';
import Typography from '../Typography/Typography';

type TooltipProps = {
  title: JSX.Element | string;
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  delay?: number;
  children: JSX.Element;
  arrow?: boolean;
};

const tooltipStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.fortnoxTooltip.background,
    borderRadius: theme.spacing(1),
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    minWidth: 400,
  },
  arrow: {
    color: theme.palette.fortnoxTooltip.background,
  },
  popper: {
    pointerEvents: 'initial',
  },
}));

const Tooltip = ({
  title,
  placement = 'bottom',
  children,
  delay = 300,
  arrow = true,
}: TooltipProps) => {
  const timeout = useRef<NodeJS.Timeout | null>(null);

  const [open, setOpen] = useState(false);

  const openTooltip = () => {
    timeout.current = setTimeout(() => {
      setOpen(true);
    }, delay);
  };

  const closeTooltip = () => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    setOpen(false);
  };

  const output = cloneElement(children, {
    onMouseEnter: (e: MouseEvent) => {
      openTooltip();
      children.props.onMouseEnter?.(e);
    },
    onMouseLeave: (e: MouseEvent) => {
      closeTooltip();
      children.props.onMouseLeave?.(e);
    },
    onFocus: (e: FocusEvent) => {
      openTooltip();
      children.props.onFocus?.(e);
    },
    onBlur: (e: FocusEvent) => {
      closeTooltip();
      children.props.onBlur?.(e);
    },
  });

  const titleElement = useMemo(() => {
    if (typeof title === 'string' && title.length) {
      return (
        <Typography color="onDark" margin="none">
          {title}
        </Typography>
      );
    }
    return title;
  }, [title]);

  return (
    <MuiTooltip
      title={titleElement}
      placement={placement}
      classes={tooltipStyles()}
      arrow={arrow}
      PopperProps={{
        onMouseEnter: openTooltip,
        onMouseLeave: closeTooltip,
      }}
      open={open}
    >
      {output}
    </MuiTooltip>
  );
};

export default Tooltip;
