import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  Divider,
  Box,
  Switch,
} from '@material-ui/core';
import { format, parseISO } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { Check, Report } from '@material-ui/icons';
import shortid from 'shortid';

import { parse } from '@agoy/dates';
import { ccyFormat } from '@agoy/common';
import {
  AccountingAccount,
  FinancialYear,
  Period,
  TaxSpecificationsAccountGroup,
} from '@agoy/api-sdk-core';
import { Comment } from '_shared/types';
import Typography from '_shared/components/Typography/Typography';
import Document from '_shared/components/Document';
import { PeriodDocument } from 'utils/usePeriodDocuments/Provider';
import {
  CheckedHistoryItemType,
  EnableForPrint,
  BalanceHistoryItemType,
} from './types';

const Container = styled.div`
  padding: ${({ theme }) => theme.spacing(1)}px;
  margin-bottom: ${(props) => props.theme.spacing(2)}px;

  @media print {
    padding: 0;
    border: none;
    margin-bottom: ${(props) => props.theme.spacing(4)}px;
  }
`;

const StyledDivider = styled(Divider)`
  background-color: ${(props) => props.theme.palette.grey[700]};
  margin-right: ${({ theme }) => theme.spacing(2)}px;
  margin-left: ${({ theme }) => theme.spacing(0.5)}px;
  width: 2px;
  height: auto;
  @media print {
    display: none;
  }
`;

const StyledBalanceHistoryBox = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const SubSectionTypography = styled.p`
  font-size: 1.2rem;
  font-weight: 700;
  letter-spacing: 0.05rem;
  padding-bottom: ${({ theme }) => theme.spacing(1)}px;
`;

const StyledCheckIcon = styled(Box)`
  margin-right: ${({ theme }) => theme.spacing(3)}px;
`;

const InternalCommentBox = styled(Box)`
  display: flex;
  flex-direction: row;
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
`;

const StyledBox = styled(Box)`
  padding: 8px;
  padding-left: 0;
  white-space: pre-wrap;
  p {
    margin-bottom: 4px;
  }
  span {
    padding-left: 0px;
  }
`;

const StyledTypography = styled(Box)`
  display: flex;
  flex-direction: 'row';
  padding-left: 8px;
`;

const CommonTypographyBlock = styled.p`
  margin: 0;
  margin-bottom: ${(props) => props.theme.spacing(2)}px;
`;

const AccountsGroupLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;
  margin-bottom: ${(props) => props.theme.spacing(2)}px;
`;

const StyledTable = styled(Table)`
  @media print {
    page-break-inside: avoid;
  }

  .MuiTableCell-head {
    width: 100px;
    padding: 3px;
    vertical-align: bottom;
    text-align: right;
    color: ${({ theme }) => theme.palette.grey[500]};

    @media print {
      page-break-inside: avoid;
      width: 90px;
    }

    &:first-of-type {
      text-align: left;
      width: 40px;
    }
    &:nth-of-type(2) {
      text-align: left;
      width: 200px;
    }
    &:last-of-type {
      padding: 3px 8px 3px 3px;
    }

    p {
      font-weight: bold;
      font-size: 0.875rem;
    }
  }
  .MuiTableCell-body {
    padding: 6px 3px;

    &:not(:first-of-type) {
      div {
        background-color: ${({ theme }) => theme.palette.common.white};
        text-align: right;
      }
    }
    &:last-of-type {
      padding: 6px 8px 6px 3px;
    }
    &:first-of-type {
      padding: 6px 3px 6px 8px;
    }
  }
  .MuiTableBody-root {
    .MuiTableRow-root {
      &:last-of-type {
        background-color: ${({ theme }) => theme.palette.grey[100]};
        div {
          background-color: ${({ theme }) => theme.palette.grey[100]};
        }
      }
    }
  }
  margin-bottom: ${(props) => props.theme.spacing(2)}px;
`;

const BodyCell = styled.div`
  padding: 4px 0 4px 4px;
`;

const AccountCell = styled.b`
  font-size: 1rem;
`;

const AccountNameCell = styled(TableCell)`
  > p {
    width: 210px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const StyledSwitchWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
`;

const StyledSwitch = styled(Switch)`
  color: ${(props) => props.theme.palette.secondary.main};
  margin-right: ${({ theme }) => theme.spacing(2)}px;
  @media print {
    display: none;
  }
`;

const StyledWrapper = styled(Box)`
  padding: 8px;
  padding-left: 0;
  white-space: pre-wrap;
  p {
    margin-bottom: 4px;
  }
  span {
    padding-left: 0px;
  }
`;

const CommentWrapper = styled(StyledWrapper)`
  @media print {
    display: flex;

    .hideForPrint {
      display: none;
    }
  }
`;

const AccountSectionLabel = styled.p`
  font-size: 1.2rem;
  font-weight: 700;
  letter-spacing: 0.05rem;
  padding-bottom: ${({ theme }) => theme.spacing(1)}px;
`;

const DocumentWrapper = styled(Box)`
  padding: 8px;
  padding-left: 0;
  white-space: pre-wrap;
  p {
    margin-bottom: 4px;
  }
  span {
    padding-left: 0px;
  }
  @media print {
    display: flex;
    width: 1000px;
    white-space: none;
    padding: 0;
  }
`;

type GroupsSpecificationInfoProps = {
  group: TaxSpecificationsAccountGroup;
  accounts: AccountingAccount[];
  financialYear: FinancialYear;
  period: Period;
  part: string | undefined;
  print?: boolean;
  documents: PeriodDocument[];
  balanceHistory?: BalanceHistoryItemType[];
  checkedHistory?: CheckedHistoryItemType;
  allInternalComments: Comment[];
  enableForPrint?: EnableForPrint;
  externalComment: string | undefined | null;
  onDocumentLoaded?: () => void;
  onSwitch?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

type AccountValues = {
  in: number;
  out: number;
};

const getAccountYearValues = (
  accounts: AccountingAccount[],
  account: string,
  currentFinancialYearId: number
): AccountValues => {
  const accountBalance = accounts.find((a) => a.number === account);
  return {
    in: accountBalance?.balances[currentFinancialYearId]?.in || 0,
    out: accountBalance?.balances[currentFinancialYearId]?.out || 0,
  };
};

const GroupsSpecificationInfo = ({
  group,
  accounts,
  part,
  print,
  documents,
  balanceHistory,
  checkedHistory,
  financialYear,
  period,
  allInternalComments,
  enableForPrint = {
    comment: {},
    documents: {},
  },
  externalComment,
  onDocumentLoaded = () => {},
  onSwitch = () => {},
}: GroupsSpecificationInfoProps): JSX.Element | null => {
  const { formatMessage } = useIntl();

  const key = `group.${group.id}.internal`;

  const internalComments = allInternalComments.filter(
    (c) => c.key === key && c.periodId === period.id
  );

  const month = useMemo(() => {
    return format(parse(period.start), 'LLLL', {
      locale: enUS,
    }).slice(0, 3);
  }, [period]);

  const filteredAccounts = useMemo(() => {
    if (group.type !== 'group') {
      return [];
    }

    return group.rows
      .filter((account) => account.type === 'account')
      .map((account) => {
        const accountBalances = accounts.find(
          (a) => a.number === account.number.toString()
        );
        return accountBalances?.balances[financialYear.id];
      });
  }, [accounts, financialYear, group]);

  const ibSum = useMemo(() => {
    return filteredAccounts.reduce((curr, sum) => curr + (sum?.in || 0), 0);
  }, [filteredAccounts]);

  const ubSum = useMemo(() => {
    return filteredAccounts.reduce((curr, sum) => curr + (sum?.out || 0), 0);
  }, [filteredAccounts]);

  const totalBalanceHistory = useMemo(() => {
    return balanceHistory?.reduce(
      (prev, current) => prev + Number(current.saldo),
      0
    );
  }, [balanceHistory]);

  const isBalancesEqual = useMemo(() => {
    return ccyFormat(group.sum.balance) === ccyFormat(group.sum.ub);
  }, [group]);

  const showExternalComments =
    !print || (enableForPrint.comment[`group${group.id}`] ?? true);

  const showDocuments =
    !print || (enableForPrint.documents[`group${group.id}`] ?? true);

  return (
    <Container>
      {group.type === 'group' && (
        <AccountsGroupLabel>
          <Typography variant="h3" margin="none">
            {formatMessage({ id: `reconciliation.row.${group.id}` })}
          </Typography>
        </AccountsGroupLabel>
      )}
      <StyledTable size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              {formatMessage({ id: 'tax.specifications.table.group' })}
            </TableCell>
            <TableCell>
              {formatMessage({ id: 'tax.specifications.table.groupName' })}
            </TableCell>
            <TableCell>
              {formatMessage({ id: 'tax.specifications.table.ibBalance' })} -{' '}
              {financialYear.start.slice(2, 4)}
            </TableCell>
            <TableCell>
              {formatMessage({ id: 'tax.specifications.table.dif' })} -{' '}
              {financialYear.start.slice(2, 4)}
            </TableCell>
            <TableCell>
              {formatMessage({ id: 'tax.specifications.table.dif' })} {month}
            </TableCell>
            <TableCell>
              {formatMessage({ id: 'tax.specifications.table.ubBalance' })}{' '}
              {month}
            </TableCell>
            <TableCell>
              {formatMessage({ id: 'tax.specifications.table.totalBalance' })}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {group.rows.map((account) => {
            const values = getAccountYearValues(
              accounts,
              account.number.toString(),
              financialYear.id
            );
            return (
              <TableRow key={`${account.number}.${account.name}`}>
                <TableCell>
                  <AccountCell>{account.number}</AccountCell>
                </TableCell>
                <Tooltip title={account.name || ''}>
                  <AccountNameCell>
                    <Typography margin="none">{account.name}</Typography>
                  </AccountNameCell>
                </Tooltip>
                <TableCell>
                  <BodyCell>{ccyFormat(values.in)}</BodyCell>
                </TableCell>
                <TableCell>
                  <BodyCell>{ccyFormat(values.out - values.in)}</BodyCell>
                </TableCell>
                <TableCell>
                  <BodyCell>{ccyFormat(account.ub - account.ib)}</BodyCell>
                </TableCell>
                <TableCell>
                  <BodyCell>{ccyFormat(account.ub)}</BodyCell>
                </TableCell>
                <TableCell>
                  <BodyCell>
                    {ccyFormat('balance' in account ? account.balance : 0)}
                  </BodyCell>
                </TableCell>
              </TableRow>
            );
          })}
          {group.type === 'group' ? (
            <TableRow>
              <TableCell>
                <AccountCell>Total</AccountCell>
              </TableCell>
              <TableCell> </TableCell>
              <TableCell>
                <BodyCell>{ccyFormat(ibSum)}</BodyCell>
              </TableCell>
              <TableCell>
                <BodyCell>{ccyFormat(ubSum - ibSum)}</BodyCell>
              </TableCell>
              <TableCell>
                <BodyCell>
                  {group?.sum ? ccyFormat(group.sum.ub - group.sum.ib) : 0}
                </BodyCell>
              </TableCell>
              <TableCell>
                <BodyCell>{group?.sum ? ccyFormat(group.sum.ub) : 0}</BodyCell>
              </TableCell>
              <TableCell>
                <BodyCell>
                  {group?.sum ? ccyFormat(group.sum.balance) : 0}
                </BodyCell>
              </TableCell>
            </TableRow>
          ) : null}
        </TableBody>
      </StyledTable>
      {balanceHistory?.length ? (
        <StyledBox>
          <StyledTypography>
            <StyledCheckIcon>
              {isBalancesEqual ? (
                <Check color="primary" />
              ) : (
                <Report color="error" />
              )}
            </StyledCheckIcon>
            <StyledBalanceHistoryBox>
              {balanceHistory
                .map((history) => (
                  <Typography margin="none" key={shortid()}>
                    Faktiskt saldo fylldes i av {history.authorName}{' '}
                    {format(parseISO(history.createdAt), 'yyyy-MM-dd')} och
                    uppgick till {ccyFormat(totalBalanceHistory)} SEK
                  </Typography>
                ))
                .at(-1)}
            </StyledBalanceHistoryBox>
          </StyledTypography>
        </StyledBox>
      ) : undefined}
      {checkedHistory ? (
        <StyledBox>
          <StyledTypography>
            <StyledCheckIcon>
              <Check color="primary" />
            </StyledCheckIcon>
            <Typography margin="none">
              Faktiskt saldo kvalitetsgranskades av {checkedHistory.authorName}{' '}
              {format(parseISO(checkedHistory.date), 'yyyy-MM-dd')}
            </Typography>
          </StyledTypography>
        </StyledBox>
      ) : null}
      {part === 'internspecifications' && internalComments.length > 0 ? (
        <StyledBox paddingTop={2}>
          <div>
            <SubSectionTypography>
              {formatMessage({
                id: 'internalComment',
              })}
            </SubSectionTypography>
            <Box>
              {internalComments.map((internalComment) => (
                <InternalCommentBox key={internalComment.createdAt}>
                  <StyledDivider orientation="vertical" flexItem />
                  <Box>
                    <CommonTypographyBlock>
                      {internalComment.comment}
                    </CommonTypographyBlock>
                    <Typography textStyle="bold">
                      {internalComment.userDisplayName}{' '}
                      {format(
                        parseISO(internalComment.createdAt),
                        'yyyy-MM-dd'
                      )}
                    </Typography>
                  </Box>
                </InternalCommentBox>
              ))}
            </Box>
          </div>
        </StyledBox>
      ) : null}
      {externalComment && showExternalComments && (
        <CommentWrapper paddingTop={2}>
          <div>
            <SubSectionTypography>
              {formatMessage({
                id:
                  part === 'internspecifications'
                    ? 'externalComment'
                    : 'comment',
              })}
            </SubSectionTypography>
            <StyledSwitchWrapper>
              {part !== 'internspecifications' && (
                <StyledSwitch
                  checked={enableForPrint.comment[`group${group.id}`] ?? true}
                  size="small"
                  name={`comment.group${group.id}`}
                  onChange={onSwitch}
                />
              )}
              <CommonTypographyBlock>{externalComment}</CommonTypographyBlock>
            </StyledSwitchWrapper>
          </div>
        </CommentWrapper>
      )}
      {documents.length > 0 && showDocuments && (
        <>
          <DocumentWrapper paddingTop={2}>
            <div>
              <AccountSectionLabel>
                {formatMessage({ id: 'hidden.event.view' })}
              </AccountSectionLabel>
              {part !== 'internspecifications' && (
                <StyledSwitch
                  checked={enableForPrint.documents[`group${group.id}`] ?? true}
                  size="small"
                  name={`documents.group${group.id}`}
                  onChange={onSwitch}
                />
              )}
            </div>
          </DocumentWrapper>
          {documents.map((document, index) => (
            <DocumentWrapper key={document.id} paddingTop={2}>
              <Document
                document={document}
                isPrint={print}
                disableBreakAfter={index === documents.length - 1}
                onLoad={onDocumentLoaded}
              />
            </DocumentWrapper>
          ))}
        </>
      )}
    </Container>
  );
};

export default GroupsSpecificationInfo;
