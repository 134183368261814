import React, { useContext } from 'react';
import styled from '@emotion/styled';

import { useSelector } from 'redux/reducers';
import { currentClient } from '_reconciliation/redux/accounting-view/selectors';
import {
  createRowData,
  createUserInputData,
} from '_reconciliation/components/ReconciliationView/HiddenRow/HiddenGroupRow/utils';
import { reformatDate } from 'utils';
import {
  BalanceHistoryItemType,
  CheckedHistoryItemType,
  EnableForPrint,
} from '_shared/components/GroupsSpecificationInfo/types';
import { PeriodDocument } from 'utils/usePeriodDocuments/Provider';
import { ActualBalances } from '_reconciliation/components/ReconciliationView/types';
import { Comment } from '_shared/types';

import AccountSpecificationsItem from './AccountSpecificationsItem';
import TaxesDataContext from '../../../service/TaxesDataContext';

const Section = styled.section`
  @media print {
    page-break-inside: avoid;
    max-width: 1280px;
    margin: 0;
    border: none;
    border-radius: 0;

    &:not(:last-of-type) {
      page-break-after: always;
    }
  }
`;

type AccountsTabProps = {
  part: string | undefined;
  accounts: string[];
  accountDocuments: PeriodDocument[];
  balanceChangeHistory: BalanceHistoryItemType[];
  checkedChangeHistory: CheckedHistoryItemType[];
  actualBalances: ActualBalances;
  internalComments: Comment[];
  enableForPrint?: EnableForPrint;
  isPrint?: boolean;
  onSwitchVisibleForPrint?: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
};

export const getAccountsDocuments = (
  account: string,
  documents: PeriodDocument[]
) => {
  return documents.filter((document) => {
    const referencedDocument = document?.references?.find(
      (ref) => ref.key === account
    );
    return referencedDocument;
  });
};

const AccountsTab = ({
  part,
  accounts,
  accountDocuments,
  balanceChangeHistory,
  checkedChangeHistory,
  actualBalances,
  internalComments,
  enableForPrint,
  isPrint = false,
  onSwitchVisibleForPrint,
}: AccountsTabProps): JSX.Element | null => {
  const { financialYear, period } = useContext(TaxesDataContext);

  const accountingBalances = useSelector(
    currentClient((state) => state.years[financialYear]?.accountingBalances)
  );
  const userInputData = useSelector(
    currentClient((state) => state.years[financialYear]?.userInput)
  );

  const accountingPeriod = accountingBalances?.periods.find(
    (p) => p.start === reformatDate(period, 'yyyyMMdd', 'yyyy-MM-dd')
  );

  if (
    !accountingBalances ||
    !userInputData ||
    !financialYear ||
    !accountingPeriod
  ) {
    return null;
  }

  return (
    <>
      {accounts.map((account) => {
        const userData = userInputData[`account${account}`];
        const accountInformation = accountingBalances?.accounts.find(
          (a) => a.number === account
        );
        if (!accountInformation) {
          return null;
        }
        const yearBalance =
          accountInformation.balances[accountingPeriod.financialYearId];
        const rowData = createRowData(
          accountingBalances.periods,
          yearBalance,
          accountInformation.periods
        );
        const data = createUserInputData(rowData, userData);
        const accountBalanceHistory = balanceChangeHistory.find(
          (history) => history.account === +account
        );
        const accountCheckedHistory = checkedChangeHistory.find(
          (history) => history.account === account
        );
        const inputData = data[accountingPeriod.id];

        const documents = getAccountsDocuments(account, accountDocuments);

        const isActualBalance = actualBalances[account];

        if (!inputData) {
          return null;
        }

        return (
          <Section id={`account${account}`} key={`${period}.${account}`}>
            <AccountSpecificationsItem
              allInternalComments={internalComments}
              inputData={inputData}
              documents={documents}
              period={accountingPeriod}
              accountInformation={accountInformation}
              enableForPrint={enableForPrint}
              balanceHistory={
                isActualBalance ? accountBalanceHistory : undefined
              }
              checkedHistory={
                accountCheckedHistory?.checked
                  ? accountCheckedHistory
                  : undefined
              }
              part={part}
              isPrint={isPrint}
              onSwitch={onSwitchVisibleForPrint}
            />
          </Section>
        );
      })}
    </>
  );
};

export default AccountsTab;
