import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import MoreVert from '@material-ui/icons/MoreVertOutlined';
import { Popover } from '@material-ui/core';
import Typography from '_shared/components/Typography/Typography';
import { ReactComponent as DeleteIcon } from 'assets/delete.svg';
import { ReactComponent as ArrowIcon } from 'assets/arrow.svg';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const PopoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 0;
`;

const MenuItem = styled(Row)`
  column-gap: 8px;
  padding: 8px 16px;

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.palette.grey[100]};
  }
`;

const IconButton = styled.button`
  all: unset;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.palette.grey[100]};
    border-radius: 12px;
  }
`;

const OptionsIcon = styled(MoreVert)`
  height: ${({ theme }) => theme.spacing(3)}px;
  width: ${({ theme }) => theme.spacing(3)}px;
`;

const UpIcon = styled(ArrowIcon)`
  fill: ${({ theme }) => theme.palette.common.black};
  width: ${({ theme }) => theme.spacing(2)}px;
`;

const DownIcon = styled(ArrowIcon)`
  rotate: 180deg;
  fill: ${({ theme }) => theme.palette.common.black};
  width: ${({ theme }) => theme.spacing(2)}px;
`;

type RowOptionsProps = {
  disableUp: boolean;
  disableDown: boolean;
  onDelete: () => void;
  onMoveUp: () => void;
  onMoveDown: () => void;
};

const RowOptions = ({
  disableUp,
  disableDown,
  onDelete,
  onMoveUp,
  onMoveDown,
}: RowOptionsProps) => {
  const { formatMessage } = useIntl();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDelete = () => {
    onDelete();
    setAnchorEl(null);
  };

  const handleMoveUp = () => {
    onMoveUp();
    setAnchorEl(null);
  };

  const handleMoveDown = () => {
    onMoveDown();
    setAnchorEl(null);
  };

  return (
    <>
      <Popover
        anchorEl={anchorEl}
        open={!!anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        onClose={() => setAnchorEl(null)}
      >
        <PopoverContainer>
          {!disableUp && (
            <MenuItem onClick={handleMoveUp}>
              <UpIcon />
              <Typography margin="none">
                {formatMessage({ id: 'table.draggable.moveUp' })}
              </Typography>
            </MenuItem>
          )}
          {!disableDown && (
            <MenuItem onClick={handleMoveDown}>
              <DownIcon />
              <Typography margin="none">
                {formatMessage({ id: 'table.draggable.moveDown' })}
              </Typography>
            </MenuItem>
          )}
          <MenuItem onClick={handleDelete}>
            <DeleteIcon />
            <Typography margin="none">
              {formatMessage({ id: 'table.draggable.removeRow' })}
            </Typography>
          </MenuItem>
        </PopoverContainer>
      </Popover>

      <IconButton onClick={handleClick}>
        <OptionsIcon />
      </IconButton>
    </>
  );
};

export default RowOptions;
