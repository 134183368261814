import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Dialog, IconButton } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { useIntl } from 'react-intl';

import Button from '_shared/components/Buttons/Button';
import Alert from '_shared/components/AlertBanners/Alert';
import Typography from '_shared/components/Typography/Typography';

const Row = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    display: flex;
    flex-direction: column;
    row-gap: ${({ theme }) => theme.spacing(3)}px;
    padding: ${({ theme }) => theme.spacing(4)}px;
  }
`;

const Buttons = styled(Row)`
  justify-content: flex-end;
  column-gap: 16px;
`;

const Header = styled(Row)`
  justify-content: space-between;
`;

const CloseIcon = styled(Close)`
  font-size: 24px;
`;

type UncheckDialogProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: () => Promise<void>;
};

const UncheckDialog = ({ open, onClose, onSubmit }: UncheckDialogProps) => {
  const { formatMessage } = useIntl();

  const [loading, setLoading] = useState(false);

  const handleDialogSubmit = async () => {
    setLoading(true);
    await onSubmit();
    setLoading(false);
  };

  return (
    <StyledDialog open={open}>
      <Header>
        <Typography variant="h2" margin="none">
          {formatMessage({ id: 'hidden.specification.removeColumnTitle' })}
        </Typography>
        <IconButton disabled={loading} size="small" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Header>

      <Alert type="warning">
        <Typography>
          {formatMessage({ id: 'hidden.specification.columnWarning' })}
        </Typography>
      </Alert>
      <Buttons>
        <Button
          label={formatMessage({ id: 'cancel' })}
          variant="text"
          disabled={loading}
          onClick={onClose}
        />
        <Button
          label={formatMessage({ id: 'hidden.specification.removeColumn' })}
          color="danger"
          loading={loading}
          onClick={handleDialogSubmit}
        />
      </Buttons>
    </StyledDialog>
  );
};

export default UncheckDialog;
